<template>
    <div>
        <ui-farm-chart ref="farmChart" :current-item="currentItem"></ui-farm-chart>
        <div style="margin:0 0 0 0;display: flex;justify-content: space-between;width: 100%">
            <div style="position: relative;margin-top: 20px;margin-left: 20px;width: 100%;margin-right: 20px">
                <p style="font-size: 16px;line-height: 28px;margin-right: 10px;min-height: 60px"
                >{{ (currentItem.remark ? '知识小贴士:' + currentItem.remark : '') }}</p>

                <div style="display: flex;justify-content: space-between">
                    <div
                            v-if="currentList && currentList.length>0"
                            class="land-list-view"
                            style="height:40px;line-height:40px;cursor:pointer;border-radius:5px;background:rgba(80,227,194,0.7);padding:0px 10px;
                            font-size:18px;text-align: center;color: white;"
                            @click="showCharts"> {{
                            currentItem.name
                        }} 24小时监测记录✓
                    </div>

                <div v-if="type===1">
                    土壤传感器：
                    <el-select

                            v-model="soilConfig"
                            class="right-select" placeholder="选择传感器" style="width: 170px;margin-right: 10px"
                            @change="soilSelect">
                        <el-option
                                v-for="item in soilConfigList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                </div>
            </div>
        </div>
        <el-row v-if="currentList && currentList.length>0"
                style="margin-left: 20px;margin-right: 20px;margin-top: 20px">
            <el-col :span="8" v-for="(item, index) in currentList" :key="index">
                <div
                        @click="onGridChick(index)"
                        :style="{marginRight:  '10px' }"
                        style="cursor:pointer;margin-bottom: 10px">
                    <div :style="{background:['#07b08a', 'rgba(14,246,195,0.7)', 'rgba(80,227,194,0.3)']}"
                         style="position:relative;display: flex;align-items: center;border:rgba(80,227,194,0.7) 2px solid;border-radius: 8px;height:80px;padding: 20px 16px">
                        <span :class="[`t-icon`,item.icon]"
                              :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)',fontSize:'50px' }"></span>
                        <div :style="{marginLeft: '12px',fontSize:'30px'}">
                            <span style="font-weight: 500;margin-bottom: 18px;"
                                  :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }">
                                {{
                                    item.name
                                }}
                            </span>
                            <span :style="{color:item.checked?'#fff':'rgba(255,255,255,0.4)' }"
                                  style="font-weight: 500;white-space: nowrap;">
                                {{ `${item.value && item.value !== '' ? item.value : '0'} ${item.unit}` }}
                            </span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div v-else style="width:100%;display: flex;height: 500px;
        margin-left: 20px;margin-right: 20px;margin-top: 20px;align-items: center;justify-content: center">
            <div style="display: flex;flex-direction: column;">
                <div style="color: #07b08a;font-size: 200px" class="t-icon t-icon-kongqijinghuaqi"></div>
                <div style="font-size: 60px;color: #07b08a;margin-top: 120px;margin-left: -20px">暂无数据</div>
            </div>
        </div>
    </div>
</template>
<script>
import UiFarmChart from '_c/ui-farm-chart.vue';

export default {
    name: 'ui-iot-info',
    components: {UiFarmChart},
    props: {
        type: {
            type: Number,
            default: 0,  // 0 知天 1 懂地
        },
        topic: {
            type: String,
            default: '',
        },
        soilConfig: {
            type: String,
            default: '',
        },
        landId: {
            type: String,
            default: '',
        },
        farmUserId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            soilConfigList: [],
            soilList: [],
            currentItem: {},
            currentList: [],
            weatherList: [
                {
                    name: '光照',
                    icon: 't-icon-rizhaoshishu_mian',
                    unit: 'lux',
                    key: 'lux',
                },
                {
                    name: '二氧化碳',
                    icon: 't-icon-eryanghuatan_mian',
                    unit: 'ppm',
                    key: 'co2',
                },
                {
                    name: '温度',
                    icon: 't-icon-daqiwendu_mian',
                    unit: '℃',
                    key: 'wendu',
                },
                {
                    name: '湿度',
                    icon: 't-icon-daqishidu_mian',
                    unit: '%',
                    key: 'shidu',
                },
                {
                    name: '雨量',
                    icon: 't-icon-jiangyuliang_mian',
                    unit: 'mm',
                    key: 'yuliang',
                },
                {
                    name: 'PM2.5',
                    icon: 't-icon-a-PM25_mian',
                    unit: 'ug/m3',
                    key: 'pm25',
                },
                {
                    name: 'PM10',
                    icon: 't-icon-PM10_mian',
                    unit: 'ug/m3',
                    key: 'pm10',
                },
                {
                    name: '气压',
                    icon: 't-icon-qiya_mian',
                    unit: 'kpa',
                    key: 'kpa',
                },
                {
                    name: '总悬浮颗粒物',
                    icon: 't-icon-TSPxuanfukeli-xian',
                    unit: 'ug/m3',
                    key: 'tps',
                },
                {
                    name: '风速',
                    icon: 't-icon-fengsu',
                    unit: 'm/s',
                    key: 'fengshu',
                },
                {
                    name: '风向',
                    icon: 't-icon-fengxiang',
                    unit: '',
                    key: 'fengxiang',
                },
                {
                    name: '雨雪',
                    icon: 't-icon-bingdongyuxue',
                    unit: 'mm',
                    key: 'yuxue',
                },
            ],
            otherList: [],
        }
    },
    methods: {
        soilSelect(event) {
            this.$http.post('app/appfarmland/save', {
                id: this.landId,
                soilConfig: event.value,
                farmUserId: this.farmUserId,
            }).then(res => {
                this.$message({type: 'success', message: '土壤传感器设置成功'})
                this.$emit('updateSoilConfig', event.value)
            }).catch(() => {
            })
        },
        onGridChick(index) {
            this.setDataItem(index)
        },
        showCharts() {
            this.$nextTick(() => {
                this.currentItem.weatherTopic = this.topic
                this.$refs.farmChart.open(this.currentItem)
            });
        },
        // 选中
        setDataItem(index) {
            this.currentList.forEach(item => {
                item.checked = false
            })
            this.currentList[index].checked = true
            this.currentItem = this.currentList[index]
            this.currentItem.topic = this.topic

            this.$forceUpdate()
        },
        reloadData() {
            this.soilConfigList = []
            this.currentList = []
            this.loadData()
        },
        loadData() {
            if (this.currentList && this.currentList.length > 0) {
                this.$forceUpdate()
            } else {
                this.$http.get(`app/iot/current/weather?topic=${this.topic}&soilConfig=${this.soilConfig}`, {}).then(res => {
                    let newList = Object.assign([], res.data.data);
                    newList.forEach(item => {
                        this.weatherList.forEach(firstItem => {
                            if (item.name === firstItem.name) {
                                firstItem.name = item.name
                                firstItem.unit = item.unit
                                firstItem.icon = item.icon
                                firstItem.value = item.value
                                firstItem.keyNum = item.keyNum
                                firstItem.remark = item.remark
                            }
                        })
                    })
                    // 移除 weatherList value 为空或者为0的数据
                    this.weatherList = this.weatherList.filter(item => {
                        return item.value && item.value !== '0'
                    })
                    // 移除 newList 中包含  weatherList的数据
                    this.otherList = newList.filter(item => {
                        return !this.weatherList.some(firstItem => firstItem.name === item.name)
                    })
                    // 移除 otherList name 后面的数字
                    this.otherList.forEach(item => {
                        item.name = item.name.replace(/\d+/g, '')
                    })
                    this.soilList = []
                    if (this.soilConfig && this.soilConfig !== '') {
                        this.otherList.forEach(item => {
                            if (item.keyNum === this.soilConfig) {
                                this.soilList.push(item)
                            }
                        })
                    } else {
                        this.soilList = this.otherList
                    }

                    if (this.type === 0) {
                        this.currentList = this.weatherList
                    } else {
                        this.currentList = this.soilList
                        console.log('土壤传感器', this.soilList)
                        this.$http.get(`app/iot/soil/topicList?topic=${this.topic}`).then(res => {
                            if (res.data.data) {
                                this.soilConfigList = res.data.data.map((item) => {
                                    return {
                                        name: item.name,
                                        value: item.key,
                                    }
                                })
                            }
                        }).catch(errors => {
                            console.log(errors)
                        })
                    }
                    this.setDataItem(0)
                }).catch(() => {
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">

</style>
