<template>
    <div>
        <ui-topic-controller ref="topicController" @refreshDataList="loadData"></ui-topic-controller>
        <ui-topic-log ref="topicLog"></ui-topic-log>
        <el-row style="margin-left: 20px;margin-right:20px;overflow-y: scroll" :style="{height:'900px'}">
            <el-col :span="8" v-for="(topicItem,topicIndex) in topicList" :key="topicIndex">
                <div style="height:120px;border-radius: 10px;position: relative;display: flex;flex-direction: column;margin-bottom:8px"
                     :style="{marginRight:  '10px',background:topicItem.checked?thirdColors[0]:thirdColors[2] ,border:thirdColors[1]+' 2px solid'}"
                >
                    <div :style="{background:thirdColors[0]}"
                         style="border-radius: 8px 8px 0 0;padding: 0 10px;font-size: 18px;align-items: center">
                        <span v-if="topicItem.type===1" class="el-icon-time"/>
                        <span style="height: 50px;line-height: 50px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;"> {{
                                topicItem.name + ''
                            }}</span>
                    </div>

                    <div style="display: flex;justify-content: space-around;padding: 5px;align-items: center;height:100%">
                        <el-button-group>
                            <el-button
                                    @click="changeTopicSwitch(topicItem,true)"
                                    :style="{background:topicItem.status === 1? thirdColors[1]:'#cccccc',color:topicItem.status === 1? '#ffffff':'#000000'}">
                                开启
                            </el-button>
                            <el-button
                                    @click="changeTopicSwitch(topicItem,false)"
                                    :style="{background:topicItem.status === 0? thirdColors[1]:'#cccccc',color:topicItem.status === 0? '#ffffff':'#000000'}">
                                关闭
                            </el-button>
                        </el-button-group>
                        <div style="font-size: 16px;line-height: 22px;display: flex;align-items: center;cursor: pointer"
                             @click="onOpenTopicLog(topicItem)">
                            <span class="t-icon t-icon-rizhi" style="font-size: 22px;"/>查看日志
                        </div>

                        <div style="font-size: 16px;line-height: 22px;display: flex;align-items: center;cursor: pointer;"
                             @click="onOpenTopic(topicItem)">
                                                        <span class="t-icon t-icon-zhilingpeizhi"
                                                              style="position: absolute;right:0;padding: 10px;font-weight: normal"></span>
                            设置
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div style="height:120px;border-radius: 10px;border:#2DB26F  1px solid;position: relative;display: flex;
                                        flex-direction: column;margin-bottom:8px"
                     :style="{marginRight:  '10px',background:thirdColors[0] ,border:thirdColors[1]+' 2px solid'}"
                     @click="onOpenTopic({})"
                >
                    <div style="display: flex;align-items: center;justify-content: center;line-height: 120px">
                        <span class="el-icon-plus"/>添加控制器
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import UiTopicController from '_c/ui-topic-controller.vue';
import UiTopicLog from '_c/ui-topic-log.vue';

export default {
    name: 'ui-iot-controller',
    components: {UiTopicLog, UiTopicController},
    props: {
        landId: {
            type: String,
            default: '',
        },
        topic: {
            type: String,
            default: '',
        },
        farmUserId: {
            type: String,
            default: '',
        },
        farmId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            thirdColors: ['rgba(71,118,183,0.7)', '#5592ea', 'rgba(59,109,180,0.2)'],
            topicList: [],
            topicStatusList:[]
        }
    },
    methods: {
        onOpenTopic(topicItem) {
            this.$nextTick(() => {
                let farmLandItem = {
                    flid: this.landId,
                    fid: this.farmId,
                    fuid: this.farmUserId,
                    wateringTopic: this.topic,
                }
                this.$refs.topicController.open(farmLandItem, topicItem)
            })
        },
        changeTopicSwitch(topic, isSwitch) {
            this.$msgbox.confirm('确定要' + (isSwitch ? '开启' : '关闭') + '指令吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                topic.isOn = isSwitch
                this.$http.post('app/appfarmtopic/send', topic).then(res => {
                    this.$message({
                        type: 'success',
                        message: '发送成功',
                    });
                    this.topicList.forEach((item) => {
                        if (item.id === topic.id) {
                            item.status = isSwitch ? 1 : 0
                        }
                    })
                }).catch(err => {
                    this.$message({
                        type: err + '\n发送失败,请检查指令是否正确？',
                        message: '发送失败',
                    });
                })
            }).catch(() => {
            });
        },
        onOpenTopicLog(topicItem) {
            this.$nextTick(() => {
                this.$refs.topicLog.open(topicItem)
            })

        },
        reloadData() {
            this.topicList = []
            console.log("价值主张")
            this.loadData()
        },
        loadData() {
            this.$http.get('app/appfarmtopic/list', {
                params: {
                    page: 1,
                    limit: 999,
                    fuid: this.farmUserId,
                    fid: this.farmId,
                    flid: this.landId,
                    orderField: 'sort',
                    order: 'asc',
                },
            }).then(res => {
                this.topicList = res.data.data.list
                this.loadStatus()
            })
        },
        loadStatus() {
            this.$http.get('app/iot/watering/status', {
                params: {
                    topic: this.topic,
                },
            }).then(res2 => {
                this.topicStatusList = res2.data.data
                this.topicList.forEach(item => {
                    if (item.type === 0) {
                        this.topicStatusList.forEach(item2 => {
                            if (item.msgOn === item2.on) {
                                item.status = item2.state
                            }
                        })
                    }
                })

            })
        },
    },
}

</script>
<style  lang="scss">

</style>
