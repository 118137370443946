<template>
    <div style="margin-left: 20px;margin-right:20px;margin-bottom:10px;position: relative;
    border-radius: 12px;color: white;"
         :style="{ border: checked  ? '#07b08a'+' 3px solid' : 'rgba(204,204,204,0.2) 3px solid',
                   background: checked ? 'rgba(46,96,152,0.5)' : 'rgba(204,204,204,0.1)'
    }"
    >
        <div style="position: absolute;z-index: 9999;"  @click.stop="onSelect()">
            <div v-for="(sub, index) in parseType" :key="index" :style="{
                      fontSize:'14px',
                      marginBottom:'10px',
                      zIndex: 1,
                      paddingRight: '10px',
                      fontWeight: 'bold',
                      lineHeight: '0px',
                      color: '#fff',
                      height: '0px',
                      border: '10px solid '+(sub.activate===1?'rgba(67,192,101,0.6)':'rgba(204,204,204,0.7)'),
                      borderTopLeftRadius:index===0?'10px':'0',
                      borderRightColor: 'transparent',
                      }">
                {{ `${sub.name}${sub.activate === 1 ? ' · 在线' : ' · 离线'}` }}
            </div>
        </div>
        <div  v-if="item.status!==1" style="background: rgba(143,156,162,0.4);height: 100%;width: 100%;border-radius: 7px;position: absolute;z-index: 2">
            <span style="transform:rotate(25deg);font-size:28px;font-weight:bold;
            color: darkred;padding:8px;border-radius: 3px;border: 2px darkred solid;position: absolute;left: 25%;bottom: 45%">
                种植已完结</span>
        </div>
        <img :src="item.imgUrl" :style="{opacity:checked?1:0.3}"  style="width: 100%;height: 160px;object-fit: cover;border-top-left-radius: 10px;border-top-right-radius: 10px" @click.stop="onSelect()"  alt=""/>
        <div style="padding:  8px 8px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px"   >
            <div style="display:flex;justify-content: space-between;align-items: center">
                <div style="font-weight: bold;font-size: 20px" @click.stop="onSelect()">{{ item.name }}</div>
                <el-button    v-if="$farmUser && $farmUser.type === 1"
                              icon="el-icon-edit"
                              style="z-index:999;font-size:14px;background: rgba(0,0,0,0.8);border: rgba(14, 246, 195, 0.7) 1px solid;
                              color: rgba(14, 246, 195, 0.7);font-weight: bold"   @click.stop="goToFarmLandEdit(item)">编辑</el-button>
            </div>

        </div>

    </div>
</template>

<script>

import {mapState} from 'vuex';
import UiFarmLandEdit from '_c/ui-farm-land-edit.vue';

export default {
    name: 'ui-farm-land-item',
    components: {UiFarmLandEdit},

    props: {
        item: {
            type: Object,
            default: () => {
                return {
                }
            },
        },
        index: {
            type: Number,
            default: 0,
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        item: {
            handler: function () {
                this.setType()
            },
            deep: true,
        },
    },
    data() {
        return {
            typeList: [{
                name: '知天',
                rgbaColor: 'rgba(67,192,101,0.6)',
                activate: 0,

            },
                {
                    name: '懂地',
                    rgbaColor: 'rgba(67,192,101,0.6)',
                    activate: 0,
                },
                {
                    name: '驭水',
                    rgbaColor: 'rgba(67,192,101,0.6)',
                    activate: 0,
                },
                {
                    name: '识情',
                    rgbaColor: 'rgba(67,192,101,0.6)',
                    activate: 0,
                },
            ],
        }
    },
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
            $farmUser: state => state.$farmUser,
        }),
        parseType() {
            let newTypeList = JSON.parse(JSON.stringify(this.typeList))
            newTypeList.forEach((sub) => {
                sub.activate = 0
            })
            newTypeList.forEach((sub, index) => {
                if (index === 0 && this.item.weatherTopic && this.item.weatherTopicStatus) {
                    sub.activate = 1
                }
                if (index === 1 && this.item.weatherTopic && this.item.soilTopicStatus) {
                    sub.activate = 1
                }
                if (index === 2 && this.item.wateringTopic && this.item.wateringTopic.length > 1 && this.item.wateringTopicStatus) {
                    sub.activate = 1
                }
                if (index === 3 && this.item.cameraConfig && this.item.cameraConfig.length > 1 && this.item.cameraConfigStatus) {
                    sub.activate = 1
                }
            })
            return newTypeList
        },
        imgWidth() {
            return `${(this.getScreenWidth - 30)}px`
        },
    },
    methods: {
        setType() {

        },
        onSelect() {
            this.$emit('onSelect', this.index)
        },
        goToFarmLandEdit(item) {
            this.$emit('onEdit', item)
        },
    },
    created() {
    },
}
</script>

<style scoped>

</style>
