<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>
            <div v-else style="display: flex;justify-content: space-between;">

                <!--                左-->
                <div style="flex:1;">
                    <dv-decoration-10 style="height: 5px;flex: 1"/>
                    <dv-border-box-13 style="flex:1;align-items: center;height: 80px">
                        <div style="margin-top: 10px;margin-left: 20px;font-size: 16px;color: #4ECFDB">
                            <div style="margin-right: 5px;padding-top: 20px;font-size: 1.2em">
                                {{ gaodeWeather.city }}气象实时数据: {{
                                    dateYear
                                }} {{
                                    dateWeek
                                }} {{ dateDay }}
                            </div>
                            <div style="display: flex;flex-direction:row;align-items: center;margin-top: 10px">
                                <span :class="['t-icon','t-icon-daqishidu_mian']"></span>
                                <div style="margin-left: 5px;margin-right: 15px;">
                                    {{ '湿度 ' + gaodeWeather.humidity + ' ℃ ' }}
                                </div>
                                <span :class="['t-icon','t-icon-daqiwendu_mian']"></span>
                                <div style="margin-left: 5px;margin-right: 15px;">
                                    {{ '温度 ' + gaodeWeather.temperature + ' ℃ ' }}
                                </div>
                                <span style="margin-right: 5px">天气</span>
                                <span :class="['t-icon',weatherIcon]"></span>
                                <div style="margin-left: 5px;margin-right: 15px;">
                                    {{ gaodeWeather.weather }}
                                </div>
                                <span style="margin-right: 5px">风向</span>
                                <span class="t-icon t-icon-tianqidafeng"></span>
                                <div style="margin-left: 5px;margin-right: 15px">
                                    {{ gaodeWeather.windPower + ' 级  ' + gaodeWeather.windDirection + '风' }}
                                </div>
                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
                <!--                  中-->
                <div style="flex: 1;margin-left: 15px;margin-right: 15px;align-items: center">
                    <div style="font-size: 50px;white-space: nowrap;margin-top: 20px;text-align: center">{{ $farmInfo.farmName }}</div>
                    <dv-decoration-6
                            style="height: 20px;margin-top: 30px"
                            :color="['#50e3c2', '#67a1e5']"
                    />
                </div>
                <div style="flex: 1">
                    <dv-decoration-10 style="transform: rotateY(180deg);height: 5px;flex: 1"/>
                    <dv-border-box-13
                            style="flex:1;transform: rotateY(180deg);display: flex;position: relative;height: 80px">
                        <div style="margin-top: 30px;transform: rotateY(-180deg);display: flex;flex-direction: column;padding-left: 20px;font-size:18px;font-weight: bold;color: white;">
                            <div style="padding-left: 30px;font-size: 16px;font-weight: bold">
                                    <span
                                            style="float: right;margin-right: 20px;;display: flex;align-items: center">账号:{{
                                            $farmUser.username
                                        }}
                                <span style="cursor:pointer;margin-left:15px;color: white;border: white 1px solid;padding: 3px 10px;background: rgba(78,207,219,0.2);border-radius: 8px"
                                      @click="logout">切换账号</span>

                                    <span style="cursor:pointer;margin-left:15px;color: #4ECFDB;border: #4ECFDB 1px solid;padding: 3px 10px;background: rgba(78,207,219,0.2);border-radius: 8px"
                                          @click="openFarmSelect">切换农场</span>
                                    </span>

                            </div>
                        </div>
                    </dv-border-box-13>
                </div>
            </div>
            <div style="display: flex;">
                <div style="height: 900px;width:450px;margin-top:10px;display: flex;flex-direction: column">
                    <div class="land-list-view">
                        <div style="text-align: center;border-bottom: 3px #07b08a solid;border-radius:7px 7px 0 0;display: flex;flex-direction: row;justify-content: space-between;align-items: center;height:60px;background: rgba(80,227,194,0.5)">
                            <div style="font-size: 20px;color: white;font-weight: bold;margin-left: 20px">
                                {{ currentLandItem.name ? currentLandItem.name : '农田' }}
                            </div>
                            <el-button size="mini" type="primary"
                                       style="font-size:14px;background: rgba(0,0,0,0.8);border: rgba(14, 246, 195, 0.7) 1px solid; color: rgba(14, 246, 195, 0.7);font-weight: bold;margin-right: 20px"
                                       icon="el-icon-edit" @click="onEdit({})"> 新增
                            </el-button>
                        </div>
                        <div :style="{height:'820px'}" style="overflow-y: scroll;margin-top: 15px">
                            <ui-farm-land-item v-for="(item,index) in landList" :key="index" :checked="item.checked"
                                               :index="index" :item="item" @onSelect="onSelect" @onEdit="onEdit"/>
                        </div>
                    </div>
                </div>
                <div class="land-list-view" style="margin-left: 20px;margin-top:10px;height: 900px;width: 1500px">


                    <el-tabs stretch
                             v-model="currentTabName"
                             style="width: 100%;height: 100%"
                             ref="tabsRef"
                             type="card"
                             @tab-click="onTabClick">
                        <el-tab-pane label="知天" name="first">
                            <ui-iot-info ref="weatherRef"
                                         :type="0"
                                         :landId="currentLandItem.id"
                                         :topic="currentLandItem.weatherTopic"/>
                        </el-tab-pane>
                        <el-tab-pane label="懂地" name="second">
                            <ui-iot-info ref="soilRef"
                                         :type="1"
                                         :landId="currentLandItem.id"
                                         :soilConfig="currentLandItem.soilConfig"
                                         :topic="currentLandItem.soilTopic || currentLandItem.weatherTopic"
                                         @updateSoilConfig="updateSoilConfig"></ui-iot-info>
                        </el-tab-pane>
                        <el-tab-pane label="驭水" name="third">
                            <ui-iot-controller ref="controllerRef"
                                               :farm-user-id="$farmUser.id"
                                               :farm-id="$farmInfo.farmId"
                                               :landId="currentLandItem.id"
                                               :topic="currentLandItem.wateringTopic"/>
                        </el-tab-pane>
                        <el-tab-pane label="识情" name="fourth">
                            <ui-live-video ref="liveVideoRef"></ui-live-video>
                        </el-tab-pane>
                        <el-tab-pane label="农迹" name="fifth">
                            <ui-farm-record ref="recordRef"></ui-farm-record>
                        </el-tab-pane>

                    </el-tabs>
                </div>
            </div>

            <ui-farm-select  ref="farmSelect"  @onSelect="loadData" />
            <ui-farm-land-edit ref="landEditRef"></ui-farm-land-edit>


        </div>
    </div>
</template>

<script>
import drawMixin from '../utils/drawMixin';
import {formatTime} from '@/utils'
import {mapState} from 'vuex';
import AMapLoader from '@vuemap/amap-jsapi-loader';
import UiFarmLandItem from '_c/ui-farm-land-item.vue';
import UiIotInfo from '_c/ui-iot-info.vue';
import UiIotController from '_c/ui-iot-controller.vue';
import UiLiveVideo from '_c/ui-live-video.vue';
import UiFarmRecord from '_c/ui-farm-record.vue';
import UiFarmLandEdit from '_c/ui-farm-land-edit.vue';
import UiFarmSelect from '_c/ui-farm-select.vue';

export default {
    mixins: [drawMixin],
    components: {
        UiFarmSelect,
        UiFarmLandEdit,
        UiFarmRecord,
        UiLiveVideo,
        UiIotController,
        UiIotInfo,
        UiFarmLandItem,
    },
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmUser: state => state.$farmUser,
            $farmInfo: state => state.$farmInfo,
            $farmList: state => state.$farmList,
        }),
    },
    data() {
        return {
            farmShow: false,
            timing: null,
            loading: true,
            isReload: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            decorationColor: ['#568aea', '#000000'],
            gaodeWeather: {
                city: '北京',
                temperature: '25',
                humidity: '50',
                weather: '晴',
                windDirection: '东北',
                windPower: '3-4',
            },
            weatherIcon: 't-icon-tianqiqingzhuanduoyun',
            currentTabName: 'first',
            currentLandItem: {},
            landList: [],
            tabList: [
                {
                    name: 'first',
                    label: '知天',
                    checked: true,
                },
                {
                    name: 'second',
                    label: '懂地',
                    checked: false,
                },
                {
                    name: 'third',
                    label: '驭水',
                    checked: false,
                },
                {
                    name: 'fourth',
                    label: '识情',
                    checked: false,
                },
                {
                    name: 'fifth',
                    label: '农迹',
                    checked: false,

                },
            ],
            farmList: [],
            farmId: '',
        }
    },
    mounted() {
        this.loading = true;
        if (!this.$token) {
            this.$router.push({name: 'login'});
        } else {
            this.loadData();
        }
    },
    beforeDestroy() {
        clearInterval(this.timing)
    },
    methods: {
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        loadData() {
            this.loading =true
            this.timeFn();
            this.loadGaoWeather();
            this.loadLandList();
            this.cancelLoading();
        },
        openFarmSelect() {
            this.$nextTick(() => {
                this.$refs.farmSelect.open()
            })
        },
        onTabClick(tab) {
            this.currentTabName = tab.name;
            if (this.currentTabName === this.tabList[0].name) {
                this.$nextTick(() => {
                    if (this.isReload) {
                        this.$refs.weatherRef.reloadData()
                        this.isReload = false
                    } else {
                        this.$refs.weatherRef.loadData()
                    }
                })
            }
            if (this.currentTabName === this.tabList[1].name) {
                this.$nextTick(() => {
                    if (this.isReload) {
                        this.$refs.soilRef.reloadData()
                        this.isReload = false
                    } else {
                        this.$refs.soilRef.loadData()
                    }
                })
            }

            if (this.currentTabName === this.tabList[2].name) {
                this.$nextTick(() => {
                    if (this.isReload) {
                        this.$refs.controllerRef.reloadData()
                        this.isReload = false
                    } else {
                        this.$refs.controllerRef.loadData()
                    }
                })
            }

            if (this.currentTabName === this.tabList[3].name) {
                setTimeout(() => {
                    this.$refs.liveVideoRef.play(this.currentLandItem.cameraConfig)
                }, 1000)
            }
            if (this.currentTabName === this.tabList[4].name) {
                this.$refs.recordRef.loadData(this.$farmInfo.farmId, this.currentLandItem.id)
            }
        },
        loadLandList() {
            const params = {
                page: 1,
                limit: 999,
                fid: this.$farmInfo.farmId,
            }
            this.$http.get('app/appfarmland/list', {
                params: params,
            }).then(res => {
                this.landList = res.data.data.list;
                this.onSelect(0)
            })
        },
        onSelect(index) {
            this.landList.forEach(item => {
                item.checked = false;
            });
            this.landList[index].checked = true;
            this.currentLandItem = this.landList[index];
            this.isReload = true;
            this.onTabClick({name: 'first'})
        },
        //获取高德天气
        loadGaoWeather() {
            this.$http.get('app/iot/ip/city', {}).then(res => {
                let that = this;
                AMapLoader.load({
                    key: '6230d00bf3fe2ffa4ca2ae8897797e6a', // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ['AMap.CitySearch', 'AMap.Geocoder', 'AMap.Weather'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                })
                        .then((AMap) => {
                            that.getWeather(AMap, res.data.data);
                        })
                        .catch((e) => {
                            console.log(e);
                        });

            });

        },
        // 获取天气
        getWeather(AMap, city) {
            let that = this;
            //加载天气查询插件
            AMap.plugin('AMap.Weather', function () {
                //创建天气查询实例
                let weather = new AMap.Weather();
                //执行实时天气信息查询
                weather.getLive(city, function (err, data) {
                    if (data.info === 'OK') {
                        that.gaodeWeather = data;
                        that.weatherIcon = that.parseWeatherIcon(that.gaodeWeather.weather);
                    }
                });
            });
        },
        updateSoilConfig(value) {
            this.currentLandItem.soilConfig = value;
        },
        parseWeatherIcon(weather) {
            if (weather) {
                if (weather.includes('小雨')) {
                    return 't-icon-tianqiyutianxiaoyu'
                } else if (weather.includes('大雨')) {
                    return 't-icon-tianqiyutiandayu'
                } else if (weather.includes('阴')) {
                    return 't-icon-tianqiyintian'
                } else {
                    return 't-icon-tianqiqingzhuanduoyun'
                }
            }
            return 't-icon-tianqiqingzhuanduoyun'
        },
        logout() {
            this.$store.commit('clearToken');
            this.$router.push({name: 'login'});
        },
        onEdit(landItem) {
            if (!landItem.id) {
                landItem.fid = this.$farmInfo.farmId
            }
            this.$refs.landEditRef.open(landItem)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.land-list-view {
    border: #07b08a 3px solid;
    border-radius: 10px;
}


::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav  {
    border: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}


::v-deep .el-tabs__item {
    color: rgba(255, 255, 255, 0.4);
    font-size: 30px;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.3);
    height: 60px;
    line-height: 60px;
}

::v-deep .el-tabs__item.is-active {
    border-radius: unset;
    color: white;
    background: #50e3c2;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-left: #07b08a 3px solid;
    border-bottom: #07b08a 3px solid;
}


::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-top-left-radius: 8px;
    border-left: #07b08a 0 solid;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(5) {
    border-top-right-radius: 8px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child.is-active {
    background: #50e3c2;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2).is-active {
    background: #43c065;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(3).is-active {
    background: #3b6db4;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(4).is-active {
    background: #3ca158;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(5).is-active {
    background: rgb(59, 140, 121);
}

</style>
