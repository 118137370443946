<template>
    <el-dialog
            title="请选择农场"
            :visible.sync="farmShow"
            width="40%"
            custom-class="farm-dialog"
            style="z-index: 9999"
            :modal="false"
    >
        <div>
            <el-select v-model="farmId" placeholder="请选择" style="width: 100%">
                <el-option
                        v-for="item in $farmList"
                        :key="item.farmId"
                        :label="item.farmName"
                        :value="item.farmId">
                </el-option>
            </el-select>
            <dv-border-box-8 style="margin-top: 30px;height: 50px;line-height: 50px;">
                <div class="login-btn" @click="onSelect">切换农场</div>
            </dv-border-box-8>
        </div>
    </el-dialog>
</template>
<script >
import {mapState} from 'vuex';

export default {
    name: 'ui-farm-select',
    computed: {
        ...mapState({
            $farmList: state => state.$farmList,
        }),
    },
    data() {
        return {
            farmShow: false,
            farmId: '',
        }
    },
    methods: {
        open() {
            this.farmShow = true
        },
        onSelect() {
            this.farmShow = false
            if(this.farmId) {
                let farmInfo = this.$farmList.find(item => item.farmId === this.farmId);
                this.$store.commit('setFarmInfo',farmInfo);
                this.$emit('onSelect');
            }
            else {
                this.$message.error('请选择农场');
            }
        }
    }
}

</script>
<style  lang="scss">
.login-btn {
    color: #4ECFDB;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    cursor: pointer;

    &:active {
        background: rgba(0, 235, 239, 0.3);
    }
}

.farm-dialog .el-dialog__header .el-dialog__title {
    color: white;
}
.farm-dialog .el-dialog__headerbtn .el-dialog__close {
    color: white;
}
</style>
