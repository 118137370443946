<template>
    <div>
        <el-row
                style="overflow-y: scroll;height: 740px;width: 100%">
            <el-col :span="16" v-for="(recordItem,index) in recordList" :key="index">
                <ui-farm-record-item :item="recordItem" :itemIndex="index"/>
            </el-col>
        </el-row>
        <el-pagination
                style="margin-top: 20px;margin-left: 10px"
                background
                layout="prev, pager, next,total"
                :page-size="pageSize"
                :current-page.sync="page"
                :total="total"
                @current-change="onCurrentChange"
                @size-change="onSizeChange"
                @prev-click="onCurrentChange(--page)"
                @next-click="onCurrentChange(++page)"
        >
        </el-pagination>
    </div>
</template>
<script>
import UiFarmRecordItem from '_c/ui-farm-record-item.vue';

export default {
    name: 'ui-farm-record',
    components: {UiFarmRecordItem},
    data() {
        return {
            recordList: [],
            pageSize: 10,
            page: 1,
            total: 0,
            fid: '',
            flid: '',
        }
    },
    methods: {
        loadData(fid,flid) {
            this.flid = flid
            this.fid = fid
            const params = {
                page: this.page,
                limit: this.pageSize,
                fid: fid,
                flid: flid,
                orderField: 'create_date',
                order: 'desc',
            }
            this.$http.get('app/appfarmrecord/list', {
                params: params,
            }).then(res => {
                this.total = res.data.data.total
                this.recordList = res.data.data.list
                this.recordList.forEach(item => {
                    item.srcList = item.imgList.map(img => {
                        return img.imgUrl
                    })
                })
            }).catch(() => {
                this.recordList = []
            })
        },
        onSizeChange(size) {
            this.pageSize = size
            this.loadData(this.fid,this.flid)
        },
        onCurrentChange(page) {
            this.page = page
            this.loadData(this.fid,this.flid)
        },
    },
}
</script>
<style  lang="scss">
.el-pagination .btn-next,.el-pagination .btn-prev{
    background-color: #101010;
}
</style>
