import { render, staticRenderFns } from "./ui-farm-land-item.vue?vue&type=template&id=f6e2118a&scoped=true"
import script from "./ui-farm-land-item.vue?vue&type=script&lang=js"
export * from "./ui-farm-land-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6e2118a",
  null
  
)

export default component.exports