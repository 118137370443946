<template>
    <el-dialog :visible.sync="visible" :custom-class="topicDialogClass" :title="`${topic.id ? '编辑' : '新增'}控制器`"
               width="700px" :modal="false" style="z-index: 9999;">
        <div style="height: 600px;overflow-y: scroll">
            <el-form :label-position="'left'" hide-required-asterisk  :model="topic"  :rules="rules">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="topic.name" placeholder="请输入控制器名称"></el-input>
                </el-form-item>

                <el-form-item label="Topic" prop="topic">
                    <el-input v-model="topic.topic" placeholder="请输入指令Topic"></el-input>
                </el-form-item>
                <el-form-item label="启动" prop="msgOn">
                    <el-input v-model="topic.msgOn" placeholder="请输入启动指令"></el-input>
                </el-form-item>
                <el-form-item label="关闭" prop="msgOff">
                    <el-input v-model="topic.msgOff" placeholder="请输入关闭指令"></el-input>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input v-model="topic.sort" type="number" placeholder="请输入排序值"></el-input>
                </el-form-item>

                <el-form-item label="模式">

                    <el-select v-model="topic.type" placeholder="待设置" style="width: 100%"  @chang="onSelectType">
                        <el-option
                                v-for="item in topicTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>

                <el-form-item v-if="topic.type===1" label="任务触发时间"   style="display: flex;flex-direction: column">
                        <el-date-picker
                                v-model="topic.postTime"
                                type="datetime"
                                placeholder="待设置"
                                default-time="12:00:00"
                                @change="onSelectPostTime"
                        >
                        </el-date-picker>
                </el-form-item>
                <el-form-item v-if="topic.type===2" label="任务触发时间"   style="display: flex;flex-direction: column">
                    <el-time-picker
                            v-model="topic.postTime"
                            @change="onSelectPostTime"
                            format="HH:mm"
                            placeholder="待设置">
                    </el-time-picker>
                </el-form-item>
                <el-form-item v-if="topic.type===1 || topic.type===2" label="任务执行持续时间"  >
                    <el-select v-model="topic.duration" placeholder="待设置" style="width: 100%"   @change="onSelectDurationTime">
                        <el-option
                                v-for="item in durationList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item v-if="topic.type===2" label="重复"   >
                    <el-select v-model="selectWeeks"   size="medium"  multiple  placeholder="待设置"  style="width: 100%;"   @change="onSelectRepetitionDay">
                        <el-option
                                v-for="item in repetitionDayList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item v-if="topic.type>0" label="启动" :custom-style="'padding:5px 0'">
                    <el-switch :value="topic.status" :inactive-value="0" :active-value="1"
                               activeColor="#2DB26F" @change="asyncChange"></el-switch>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <el-button-group style="box-shadow: 0 0 10px 10px rgba(80, 227, 194, 0.3)">
                <el-button type="primary" size="medium"
                           style="font-size:20px;background: black;border: rgba(14, 246, 195, 0.7) 1px solid;color: rgba(14, 246, 195, 0.7);font-weight: bold"
                           plain @click="saveTopic(topic)">保存
                </el-button>
                <el-button v-if="topic.id" size="medium" @click="deleteTopic(topic.id)"
                           style="font-size:20px;background: rgba(77,40,40,0.5);border: rgba(14, 246, 195, 0.7) 1px solid;color: rgba(14, 246, 195, 0.7);font-weight: bold">
                    删除
                </el-button>
            </el-button-group>
        </template>
    </el-dialog>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'ui-topic-controller',
    computed: {
        ...mapState({
            $token: state => state.$token,
            $farmInfo: state => state.$farmInfo,
            $farmDetailInfo: state => state.$farmDetailInfo,
            $farmUser: state => state.$farmUser,
        }),
    },
    data() {
        return {
            topicDialogClass: 'topic-dialog-cls', // 自定义对话框的背景样式
            visible: false,
            topic: {},
            showSelectMode: false,
            showSelectTime: false,
            showDuration: false,
            showSelectWeek: false,
            farmLandInfo: {},
            rules: {
                name: [
                    {required: true, message: '请输入控制器名称', trigger: 'blur'},
                ],
                topic: [
                    {required: true, message: '请输入指令Topic', trigger: 'blur'},
                ],
                msgOn: [
                    {required: true, message: '请输入启动指令', trigger: 'blur'},
                ],
                msgOff: [
                    {required: true, message: '请输入关闭指令', trigger: 'blur'},
                ],
                sort: [
                    {required: true, message: '请输入排序值', trigger: 'blur'},
                ],
            },
            topicTypeList: [
                {label: '手动:执行一次', value: 0},
                {label: '定时:执行一次', value: 1},
                {label: '定时:重复执行', value: 2},
            ],
            durationList: [
            ],
            selectWeeks: [],
            repetitionDayList: [
                {label: '周一', value: 1},
                {label: '周二', value: 2},
                {label: '周三', value: 3},
                {label: '周四', value: 4},
                {label: '周五', value: 5},
                {label: '周六', value: 6},
                {label: '周日', value: 0},
            ],
        };
    },
    methods: {
        open(farmLandItem, topicItem) {
            if (topicItem && topicItem.id) {
                this.topic = topicItem
                if(topicItem.weeks) {
                    this.selectWeeks = JSON.parse(topicItem.weeks)
                }
                else {
                    this.selectWeeks =[]
                }
            } else {
                this.topic = {
                    name: '',
                    topic: farmLandItem.wateringTopic,
                    msgOn: '',
                    msgOff: '',
                    sort: 0,
                    type: 0,
                    status: 0,
                    postTime: '',
                    duration: '',
                    weeks: '',
                }
                this.selectWeeks =[]
            }
            this.farmLandInfo = farmLandItem
            this.durationList = []
            for (let i = 1; i <= 30; i++) {
                this.durationList.push({label: i + '分钟', value: i * 60})
            }
            this.visible = true
        },
        asyncChange(value) {
            this.$msgbox({
                title: '提示',
                message: value ? '确定要打开吗' : '确定要关闭吗',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        this.topic.status = value
                        this.$forceUpdate()
                    }
                    done()
                },
            })
        },
        parseShowWeeks() {
            if (this.topic.weeks) {
                let weeks = JSON.parse(this.topic.weeks)
                if (weeks.length === 7) {
                    return '每天'
                } else {
                    return weeks.map(item => {

                        switch (item) {
                            case 1:
                                return '周一'
                            case 2:
                                return '周二'
                            case 3:
                                return '周三'
                            case 4:
                                return '周四'
                            case 5:
                                return '周五'
                            case 6:
                                return '周六'
                            case 0:
                                return '周日'
                        }
                    }).join(',')
                }
            } else {
                return ''
            }

        },
        deleteTopic(topicId) {
            this.$msgbox({
                title: '提示',
                message: '确定要删除该指令吗？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        this.$http.delete('app/appfarmtopic/delete', {
                            data: {id: topicId},
                        }).then(res => {
                            console.log('res', res.data)
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            })
                            this.visible = false
                            this.$emit('refreshDataList')
                        }).catch(err => {
                            this.$message({
                                message: err + '\n删除失败',
                                type: 'error',
                            })

                        })
                    }
                    done()
                },
            })

        },
        saveTopic(topic) {
            if (!topic.name) {
                this.$message({
                    message: '请输入指令名称',
                    type: 'warning',
                })
                return
            }
            if (!topic.topic) {
                this.$message({
                    message: '请输入指令Topic',
                    type: 'warning',
                })
                return
            }
            if (!topic.msgOn) {
                this.$message({
                    message: '请输入启动指令',
                    type: 'warning',
                })
                return
            }
            if (!topic.type && topic.type !== 0) {
                this.$message({
                    message: '请选择执行模式',
                    type: 'warning',
                })
                return
            }
            if (topic.type === 1) {
                if (!topic.postTime) {
                    this.$message({
                        message: '请选择执行时间',
                        type: 'warning',
                    })
                    return
                }
            }
            if (topic.type === 2) {
                if (this.selectWeeks.length === 0) {
                    this.$message({
                        message: '请选择重复日期',
                        type: 'warning',
                    })
                    return
                }
            }
            topic.weeks = JSON.stringify(this.selectWeeks)
            topic.fuid = this.farmLandInfo.fuid
            topic.fid = this.farmLandInfo.fid
            topic.flid = this.farmLandInfo.id
            this.$http.post('app/appfarmtopic/save', topic).then(res => {
                console.log('res', res.data)
                this.$message({
                    message: '保存成功',
                    type: 'success',
                })
                this.visible = false
                this.$emit('refreshDataList')
            }).catch(err => {
                this.$message({
                    message: err + '\n保存失败',
                    type: 'error',
                })
            })
        },
        onSelectType(value) {
            this.topic.type = value
            // if (value === 1) {
            //     this.topic.postTime = formatTime(new Date().getTime(), 'yyyy-mm-dd hh:MM')
            // }
            // if (value === 2) {
            //     this.topic.postTime = formatTime(new Date().getTime(), 'hh:MM')
            // }
        },
        onSelectPostTime() {
        },
        onSelectDurationTime(value) {
            this.topic.duration = value
        },
        onSelectRepetitionDay(value) {
            this.selectWeeks = value
        },
    },
}

</script>

<style lang="scss" >
.topic-dialog-cls .el-dialog {
    border: rgba(14, 246, 195, 0.7) 4px solid;
    border-radius: 15px 15px 8px 8px;
    background: transparent;
    box-shadow: 0 0 10px 10px rgba(80, 227, 194, 0.3); /* 阴影 */
    outline: 5px solid #07b08a; /* 描边 */
    outline-offset: -5px; /* 描边偏移量，使其与元素大小相同 */
}

.topic-dialog-cls .el-dialog__header {
    border-radius: 15px 15px 0 0;
    background-color: rgb(11, 32, 51);
}

.topic-dialog-cls .el-dialog__title {
    color: #00ebef;
    font-size: 20px;
    font-weight: bold;
}

/*body背景色*/
.topic-dialog-cls .el-dialog__body {
    border-radius: 0 0 8px 8px;
    background-color: rgba(0, 0, 0, 0.92);
}
.topic-dialog-cls .el-select__tags-text{
    color: #4ECFDB;
    font-size: 14px;
}
.topic-dialog-cls .el-tag.el-tag--info{
    background:black;
}
.topic-dialog-cls .el-tag.el-tag--info .el-tag__close{
    color: #4ECFDB;
    background: black;
}


.el-select--medium{
    background: black;
}

.el-select-dropdown{
    background: #020308;
    border: #5cd9e8 2px solid;
    border-radius: 5px;
    color: #4ECFDB;
}
.el-select-dropdown__item{
    color: rgba(78, 207, 219, 0.7);
    font-size: 20px;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
    background:  rgba(0, 235, 239, 0.2);
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
    background:  rgba(0, 235, 239, 0.4);
    color: white;
}
.el-popper[x-placement^=bottom] .popper__arrow::after{
    border-bottom-color: transparent;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after{
    border-style: none !important;
}

.topic-dialog-cls .el-form-item--small .el-form-item__content, .el-form-item--small .el-form-item__label {
    line-height: 40px;
    color: #00ebef;
    font-size: 20px;
}
.el-input--small .el-input__inner {
    background: transparent;
    color: white;
    font-size: 20px;
    height: 40px;
    border-color: rgba(0, 235, 239, 0.4);
}
.el-input--medium .el-input__inner {
    background: transparent;
    color: white;
    font-size: 20px;
    height: 40px;
    border-color: rgba(0, 235, 239, 0.4);
}
.el-picker-panel .el-input--small .el-input__inner {
    background: transparent;
    color: black;
    font-size: 20px;
    height: 40px;
    border-color: grey;
}
::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}



</style>
