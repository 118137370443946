<template>
    <el-dialog  :visible.sync="visible"  :custom-class="customDialogClass"  :title="currentItem.name+'24小时监测记录'"
                width=60%  :modal="false"  style="z-index: 9999">
        <div >
            <div class="charts-box">
                <qiun-vue-ucharts
                        type="line"
                        :inScrollView="false"
                        :opts="opt"
                        :chartData="chartsDataLine"
                />
            </div>
        <div  v-if="currentItem.remark" style="margin-left: 15px;margin-right: 15px;text-align: center;color: white;font-size: 16px;margin-top: 10px">
            <p>{{currentItem.remark}}</p>
        </div>
        <div style="text-align: center;justify-content: center;color: white;font-size: 20px;margin-top: 20px">
            <p style="text-align: center;"> 当前{{currentItem.name}}<span style="color:#07b08a;margin-left: 5px;font-weight: bold ">{{dataAndUnit}}</span></p>
        </div>
        </div>
    </el-dialog>
</template>

<script>
import qiunVueUcharts from '@qiun/vue-ucharts'
export default {
    name: 'ui-farm-chart',
    components: {
        qiunVueUcharts
    },
    props: {
    },
    data() {
        return {
            customDialogClass:'custom-dialog-background', // 自定义对话框的背景样式
            visible: false,
            currentItem: {},
            opt: {},
            hoursDataList: [],
            chartsDataLine: {},
        };
    },
    computed: {
        dataAndUnit() {
            if(this.currentItem.data){
                return this. currentItem.data + this. currentItem.unit
            }
            return '--' + this. currentItem.unit
        }
    },

    mounted() {

    },
    methods:{
        open(currentItem) {
            this.currentItem = currentItem
            this.loadChartsData()
        },
        loadChartsData() {
            this.$http.get(`app/iot/farm/weather/hours?topic=${this.currentItem.weatherTopic}&key=${this.currentItem.key}`, {}).then(res => {
                this.visible = true
                this.currentItem.hoursDataList = res.data.data
                this.showCharts(this.currentItem)
            })
        },
        showCharts(item) {
            if (item && item.name) {
                let dataList  = item.hoursDataList
                let timeList = []
                let dataChart = []
                let canShow = false
                for (let i = 0; i < dataList.length; i++) {
                    if(dataList[i].value && Number(dataList[i].value) > 0) {
                        canShow = true
                        break
                    }
                }
                for (let i = 0; i < dataList.length; i++) {
                    if(dataList[i]) {
                        timeList.push(dataList[i].showTime)
                        if(canShow) {
                            dataChart.push(dataList[i].value ? Number(dataList[i].value) : 0)
                        }
                    }
                }
                let parseData = {
                    categories: timeList,
                    series: [
                        {
                            name: item.name,
                            lineType: 'solid',
                            smooth: true,
                            data: dataChart,
                            formatter: function (val) {
                                return val + ' ' + item.unit;
                            },
                        },
                    ],
                };
                this.chartsDataLine = parseData;
                this.opt = {
                    type: 'line',
                    dataLabel: false,
                    dataPointShape: true,
                    dataPointShapeType: 'hollow',
                    enableMarkLine: true,
                    onzoom: true,
                    pixelRatio: 2,
                    categories: parseData.categories,
                    series: parseData.series,
                    animation: true,
                    enableScroll: false,
                    tooltipShow: true,
                    boundaryGap: 'justify',
                    background: '#fff',
                    color: ['#07b08a'],
                    padding: [30, 20, 0, 20],
                    xAxis: {
                        marginTop: 20,
                        disableGrid: true,
                        rotateLabel: true,
                        rotateAngle: 50,
                        fontSize: 14,
                        fontColor: 'white',
                    },
                    yAxis: {
                        gridType: 'dash',
                        dashLength: 4,
                        min: 0,
                        fontSize: 16,
                        data: [
                            {
                               fontSize: 16,
                               fontColor: 'white',
                            },
                        ],

                    },
                    extra: {
                        tooltip: {
                            showCategory: false,
                        },
                        fontColor: '#fff',
                        line: {
                            type: 'curve',
                            width: 4,
                            activeType: 'hollow',
                            linearType: 'custom',
                        },
                        markLine: {
                            lineColor: '#07b08a',
                        },
                    },
                    legend: {
                        show: true,
                    },
                }
            }
        },
    }
}
</script>

<style lang="scss">
.charts-box{
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}
/* 自定义对话框的背景样式 */
.custom-dialog-background .el-dialog__header  {
    border-radius: 15px 15px 0 0;
    background-color: #183252;
}

.custom-dialog-background .el-dialog__title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}
    /*body背景色*/
.custom-dialog-background .el-dialog__body {
    border-radius: 0 0 8px 8px;
    background: rgba(16, 69, 115, 0.95);
}
.el-dialog {
    border: rgba(14,246,195,0.7) 4px solid;
    border-radius: 15px 15px 8px 8px;
    background: transparent;
    box-shadow: 0 0 10px 10px rgba(80,227,194,0.3); /* 阴影 */
    outline: 5px solid #07b08a; /* 描边 */
    outline-offset: -5px; /* 描边偏移量，使其与元素大小相同 */
}
</style>
